<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        label="ชื่อ"
        class="col-12"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        label="ค่า"
        class="col-6"
        v-model="formData.options.value"
        :validations="[
          {text: 'required!', value: v.formData.options.value.$dirty && v.formData.options.value.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        label="ค่าสูงสุด"
        class="col-6"
        v-model="formData.options.maxValue">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="css">
</style>
